<div class="modal-body" *ngIf="!isDemoLogin && !signUpPage">
   <button type="button" class="close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
   </button>

   <h2>login</h2>
   <p>welcome!</p>
   <form [formGroup]="formGroup" (ngSubmit)="onLogin()">
     <div class="form-group">
       <label for="username">username</label>
       <input type="email" formControlName="username" class="form-control" id="username" aria-describedby="emailHelp" placeholder="Enter username">
       <small *ngIf="f.username.touched && f.username.errors?.required" class="error">Required</small>
     </div>
     <div class="form-group">
       <label for="password">Password</label>
       <input formControlName="password" type="password" class="form-control" id="password" placeholder="Password">
       <small *ngIf="f.password.touched && f.password.errors?.required" class="error">Required</small>
     </div>
     <div class="form-group form-check">
       <input type="checkbox" formControlName="termsCondition" class="form-check-input" id="years">
       <label class="form-check-label" for="years">i am over 18 years old</label>
     </div>
     <!-- <div class="form-group form-check">
       <input type="checkbox" class="form-check-input" id="rememeber">
       <label class="form-check-label" for="rememeber">remember me</label>
     </div> -->
     <div class="btn-group d-flex">
      <button type="submit" class="btn btn-login" [disabled]="formGroup.invalid || isLoad">login &nbsp; &Tab; <i *ngIf="isLoad" class="fa fa-spinner"></i></button>
       <button type="button" class="btn btn-login" [disabled]="isLoadDemo" (click)="loginWIthDemoId()" *ngIf="env.host.demoLogin" style="background:#13a613 !important;">
         Demo Login
         <!-- <i *ngIf="!isLoadDemo" class="mdi mdi-logout-variant"></i> -->
         <!-- <i *ngIf="isLoadDemo" class="mdi mdi-spin mdi-loading"></i> -->
       </button>
   </div>

    <div class="text-right mt-1" *ngIf="env.host.signUpActive" (click)="openResetPassModal(resetpass)"><span style="cursor: pointer;">Forgot Password?</span></div>
   </form>
 </div>
 <div class="modal-footer" *ngIf="!isDemoLogin && !signUpPage">
   <a href="mailto:{{env.host.email}}" class="login-mail">
       <img class="img-fluid" src="http://speedcdn.io/assets/kesar-client/icons/login-mail.png">
       {{env.host.email}}
   </a>
   <a *ngIf="env.host.name != 'kesar365'" [href]="env.host.whatsapp" target="_blank" class="login-whatsapp">
     <img class="img-fluid" src="http://speedcdn.io/assets/kesar-client/icons/login-phone.png">
     +{{env.host.contactNo}}
   </a>
   <a *ngIf="env.host.name == 'kesar365'" href="https://linktr.ee/kesar365" target="_blank" class="login-whatsapp">
     <img class="img-fluid" src="http://speedcdn.io/assets/kesar-client/icons/login-phone.png">
     Whatsapp
   </a>
 </div>

 <iframe *ngIf="signUpPage" class="embed-responsive-item signup-iframe" scrolling="yes" [src]="env.host.iframeDomainUrl + 'signup/' + env.domain + env.trafficTag | safeURL" allowfullscreen="true" title="game"></iframe>

 <ng-template #resetpass>
  <div class="modal-body resetpass p-0">
    <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">×</span>
    </button>
    <iframe class="embed-responsive-item resetpass-iframe" scrolling="yes" [src]="env.host.iframeDomainUrl + 'forgot-password/' +env.domain | safeURL" allowfullscreen="true" title="game"></iframe>
  </div>
</ng-template>